@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Quicksand:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.email-response {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0;
  font-family: "Manrope", sans-serif;
  width: 100%;
  overflow-x: hidden;
}

.wrapper {
  padding: 0 20px;
  text-align: center;
}

.logo-inisa {
  max-width: 125px;
  margin-bottom: 30px;
}

img.confirmed-image {
  max-width: 350px;
  margin: 30px 0 40px 0;
  text-align: center;
}

.heading-1 {
  font-family: "Quicksand", "sans-serif";
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f4144;
  margin-bottom: 24px;
}

.heading-2 {
  max-width: 500px;
  font-family: "Manrope", "sans-serif";
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.94;
  letter-spacing: normal;
  text-align: center;
  color: #3f4144;
  margin: auto;
}

.button__link {
  border-radius: 8px;
  background-color: #017632;
  width: 328px;
  font-family: "Manrope", "sans-serif";
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border: none;
  padding: 11px 0;
  margin-top: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .heading-3 {
    padding: 0 30px;
  }
}

